// Overrides
@if $bulmaswatch-import-font {
  @import url("https://fonts.googleapis.com/css?family=Open+Sans:400,700&display=swap");
}

@mixin btn-shadow($color) {
  background-image: linear-gradient(
    180deg,
    lighten($color, 3%) 0%,
    $color 60%,
    darken($color, 3%) 100%
  );
  filter: none;
}

.button {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      &:not(.is-outlined):not(.is-inverted) {
        border: 1px solid darken($color, 6.5%);
        @include btn-shadow($color);
      }
    }
  }
}

.input,
.textarea {
  box-shadow: none;
}

.card .card-header {
  border-bottom: 1px solid $border;
}

.notification {
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);
    $color-lightning: max((100% - lightness($color)) - 2%, 0%);
    &.is-#{$name} {
      background-color: lighten($color, $color-lightning);
      color: $color;
      border: 1px solid lighten($color, 30);
    }
  }
}

.navbar {
  @include btn-shadow($primary);
  .has-dropdown .navbar-item {
    @include desktop {
      color: $text;
    }
  }

  @include touch {
    .navbar-menu {
      background-color: inherit;
    }
  }

  .navbar-burger span {
    background-color: $navbar-item-color;
  }

  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      @include btn-shadow($color);
      @include touch {
        .navbar-item,
        .navbar-link {
          color: rgba($color-invert, 0.7);

          &.is-active {
            color: $color-invert;
          }
        }

        .navbar-burger span {
          background-color: $color-invert;
        }
      }
    }
  }
}

.hero {
  // Colors
  .navbar {
    background-color: $primary;
    @include btn-shadow($primary);
  }
  @each $name, $pair in $colors {
    $color: nth($pair, 1);
    $color-invert: nth($pair, 2);

    &.is-#{$name} {
      .navbar {
        background: none;
      }
    }
  }
}
